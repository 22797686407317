@import "../../styles/colorDefaults.scss";

.message-inbox {
     display: flex;
     flex-direction: column;
    height: 100%;

    .search-messages {
        display: flex;
        padding: 2px 10px;

        .input-wrapper{
            width: 100%;
            padding-left: 2px;
            border: solid thin gray;
        }

        .MuiTextField-root label {
            color: white;
        }
        .MuiTextField-root input  {
            color: white!important;
        }
    }
    
    .search-messages-hide {
        display: none;
    }

    .list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $secondary-background-color;
    margin: 5px 10px;
    height: 70px;
    padding: 5px;
    // border-left: solid 8px red;
    .name {
        display: flex;
        color: rgb(216, 214, 214);
        text-transform: uppercase;

        .MuiSvgIcon-root {
            font-size: 15px;
        }
        span {
            margin-right: 5px;
        }
    }

    .message {
        width: 300px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

}


.list-item.unread {
    border-left: solid 8px red;
}

.unread .MuiSvgIcon-root {
    color: red;
}

.list-item:hover {
    cursor: pointer;
    background: gray;
}

.messages-placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // flex-grow: 1;
    margin: 10% 0px;
    .MuiSvgIcon-root {
        color: $dark-primary-background;
        font-size: 160px;

    } 
}

}