@import '../../styles/colorDefaults.scss';

.RegionMenu-map-selection-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        text-align: center;
        color: white;
        font-size: 1rem;
    }
    p {
        text-align: center;
        color: white;
        margin: 5px;
    }

    .clear-button {
        margin-top: 10px;
        padding: 4px 7px 5px 7px;
        color: white;
        background-color: $secondary-background-color;
        border-radius: 3px;
        cursor: pointer;
    }
}

.RegionMenu-map-selection-container.waypoint {
    justify-content: flex-start;

}