.Footer {
    display: flex;
    background-color: black;
    justify-content: center;

    span {
        color: white;
        text-align: center;
        font-size: 0.7rem;
        margin: 5px 0;
        user-select: none;
    }
}