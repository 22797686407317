
@import '../../../styles/colorDefaults.scss';

.login {
    color: $light-gray-font;
    display: flex;
    flex-grow: 1;
    justify-content: center;

    .login-form {
        display: flex;
        flex-direction: column;
        width: 270px;

        .icon-container {
            display: flex;
            justify-content: center;
            align-items: center;
            background: $mid-primary-background;
            height: 270px;
            color: white;
        }
        .form-box {
            display: flex;
            justify-content: center;
            background: $dark-primary-background;
            padding: 10px;

            .MuiInputBase-root { 
               background: white;
            }
        }
    
        label {
            color: rgb(175, 170, 170);
        }

        .MuiOutlinedInput-notchedOutline {
            // border-color: aqua;
            background-color: none;
            color: rgb(80, 78, 78);
        }

        .submit-button {
            margin-top: 20px;
        }

    }

}