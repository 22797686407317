.TimePicker {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.TimePicker-field {
    width: 100%;
    height: 19px;
    background-color: #c5c5c5;
    color: black;
    text-align: center;
    line-height: 1.1rem;
}

.TimePicker-list-container {
    width: 100%;
    border-left: 2px #545454 solid;
    border-right: 2px #545454 solid;
    border-bottom: 2px #545454 solid;
    height: 144px;
    display: flex;

    .left-time-selector {
        width: 50%
    }
    .right-time-selector {
        width: 50%
    }
}

.TimePicker-list-container ul {
    flex: 1;
    height: 100%;
    margin: 0;
    padding: 0;
}

.TimePicker-list-container  ul > li {
    list-style: none;
    text-align: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.TimePicker-list-container  ul > li.selected {
    background-color: #3158c7 !important;
}

.TimePicker-list-container  ul > li:hover {
    background-color: #535454
}

/* Scrollbar CSS */
.TimePicker-list-container > div > div:last-child {
    top: 1px !important;
    bottom: 0px !important;
    right: 0px !important;
    background-color: #2c2c2c;
}

.TimePicker-list-container > div > div:last-child > div {
    border-radius: 0 !important;
    // background-color: #3158c7 !important;
}