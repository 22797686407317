@import "../../styles/colorDefaults.scss";

.cesium-widget-credits {
    display: none !important;
}

.cesium-control-panel {
    z-index: 2;
    display: flex;
    align-self: flex-start;
    pointer-events: none;

    .cesium-button-panel {
        margin: 10px;
        display: flex;
        flex-direction: column;
        width: 40px;
        pointer-events: auto;

        .cesium-custom-button {
            background-color: $dark-primary-background;
            width: 100%;
            text-align: center;
            padding: 5px;
            margin: 2px;
            border-radius: 3px;
            cursor: pointer;
            transition: background-color 0.1s linear;

            &.selected {
                background-color: $dark-primary-font;
            }
        }
    }

    .region-toggle-container {
        margin: 12px;
        align-self: flex-start;
        pointer-events: auto;

        &>.MuiFormControlLabel-root {
            background-color: $dark-primary-background;
            padding: 0 7px 0 0;
            border-radius: 3px;
            user-select: none;
        }

        .Mui-checked {
            .MuiSwitch-thumb {
                background-color: $dark-primary-font;
            }
        }

        .Mui-checked+.MuiSwitch-track {
            background-color: $dark-primary-font;
        }
    }
}