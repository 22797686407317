@import "../../../styles/colorDefaults.scss";

.list-component {
  display: flex;
  background: none;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 5px 5px 5px 10px;

  & > .left {
    min-height: 24px; //to accomodate close icon

    .icon {
      .MuiSvgIcon-root {
        font-size: 20px;
        margin-right: 10px;
      }
    }

    .text {
      user-select: none;
    }
  }

  & > .right {
    display: flex;
  }
}

.list-component:hover {
  cursor: pointer;
  // background: $light-primary-background;
  background: $secondary-background-color;
  color: white;
}
