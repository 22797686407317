.locationMap {
    flex-grow: 1;
}

.heatmap-btn {
    position: absolute;
    padding: 1px 6px;
    margin-top: 24px;
    z-index: 2;
}
.heatmap-btn-on {
    position: absolute;
    padding: 1px 6px;
    margin-top: 24px;
    z-index: 2;
    background: rgb(145, 145, 145);
    color: white;
}

.ol-rotate, .ol-attribution {
    display: none;
}
