@import "../../../styles/colorDefaults.scss";


.new-request {
    // display: flex;
    flex-grow: 1;
    min-height: 1px;

    .map-container {
        height: 0px;
    }


    &>.two-column-wrapper {
        display: flex;
        height: 100%;
        align-items: stretch;
        width: 100%;
        justify-content: space-between;
        overflow: hidden;


        &>.left {
            display: flex;
            flex-direction: column;
            color: white;
            width: 20%;
            min-width: 300px;
            background: $dark-secondary-background;
            z-index: 2;

        }

        &>.middle {
            display: flex;
            flex-direction: column;
            background: none;
            margin: 0;
            position: relative;
            width: 80%;
        }


        &>.right {
            display: flex;
            width: 20%;
            min-width: 410px;
            // flex-grow: 1;
            background: $dark-secondary-background;
            display: flex;
            flex-direction: column;
            z-index: 2;
        }
    }

    .accordion-component-wrapper {
        height: 100%;

        //EXPANSION PANEL START
        .MuiAccordionSummary-root {
            //header area of dropdown
            background: $dark-primary-background !important;
            padding: 5px !important;
            color: white !important
        }

        // .MuiAccordionSummary-root.Mui-expanded {
        //     //header area of dropdown
        //     height: 15px !important;
        //     min-height: 27px !important;

        // }

        .MuiIconButton-label {
            //icon inside header area
            color: white !important;
        }

        .MuiAccordionSummary-content {
            align-items: center;
        }

        .MuiButtonBase-root {
            height: 15px !important;
            min-height: 27px !important;
        }

        .MuiPaper-root.MuiAccordion-root {
            // content area inside each dropdown
            background: none !important;
            border: none !important;
            display: flex;
            flex-direction: column;
            color: $secondary-font-color !important;
        }
        //EXPANSION PANEL END

        .MuiAccordion-root.Mui-expanded {
            margin: 0px;
            overflow: hidden;
        }

        .MuiCollapse-container {
            flex-grow: 1;
        }

        .MuiCollapse-entered .MuiCollapse-wrapper {
            height: 100%;
            background: none !important;
        }

        .MuiAccordionDetails-root {
            display: flex;
            flex-direction: column;
            padding: 0px !important;
        }

        .left-column-charts-container .MuiCollapse-wrapper {
            height: 100%;
        }

        .MuiCollapse-wrapper>div>div {
            height: 100%;
        }

        .launch-icon {
            position: absolute;
            top: 20px;
            right: 20px;
            color: white;
            cursor: pointer;
        }
    }
}