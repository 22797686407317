.TimeRange-container {
    display: flex;
    flex-direction: column;
}

.TimeRange-top-container {
    display: flex;
    margin-bottom: 5px;
}

.TimeRange-bottom-container {
    display: flex;
    margin-top: 5px;
}

/* TimeRange Left Column CSS */
.TimeRange-top-container>.TimeRange-left-column {
    width: 95%;
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.TimeRange-bottom-container>.TimeRange-left-column {
    width: 60%;
    padding-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.TimeRange-left-column>label {
    width: 30%;
    text-align: center;
    transition: color 0.2s linear;
}

.TimeRange-left-column>label.disabled {
    color: grey
}

.TimeRange-left-column>label:first-of-type {
    margin-bottom: 15px;
}

.TimeRange-left-column>input {
    width: 65%;
    border: none;
    background-color: #c5c5c5;
    outline: none;
    text-align: center;
    transition: background-color 0.2s linear
}

.TimeRange-left-column>input.selecting {
    background-color: #fff;
}


.TimeRange-left-column>input.invalid {
    border: 1px red solid
}

.TimeRange-left-column>input:disabled {
    background-color: grey
}

.TimeRange-left-column>input:first-of-type {
    margin-bottom: 15px;
}

.TimeRange-left-column-divider {
    width: 65%;
    /* margin-top: 15px; */
    margin-left: 30%;
    text-align: center;
    color: grey
}

.TimeRange-goto-current {
    width: 65%;
    margin-top: 11px;
    margin-left: 30%;
    padding: 2px;
    text-align: center;
    background-color: #535454;
    border-radius: 4px;
    transition: transform 0.1s linear;
}

.TimeRange-goto-current:hover {
    transform: scale(1.05)
}

.TimeRange-goto-current>img {
    float: left;
    margin: 1.5% 0 0 4px;
    width: 8%;
    height: 15px;
}

.TimeRange-goto-current>p {
    float: left;
    width: 85%;
    margin: 0;
    user-select: none;
    cursor: pointer;
}

/* TimeRange Right Column CSS */
.TimeRange-top-container>.TimeRange-right-column {
    width: 30%;
    padding: 20px 5% 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.TimeRange-bottom-container>.TimeRange-right-column {
    width: 30%;
    padding: 5px 5% 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.TimeRange-play-box {
    width: 100%;
    height: 94.5%;
    display: flex;
    transition: opacity 0.2s linear
}

.TimeRange-play-box.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.TimeRange-play-button {
    width: 35%;
    padding: 10px;
    background-color: #535454;
    border-radius: 4px;
    margin-right: 5%;
    cursor: pointer;
    transition: transform 0.1s linear;
}

.TimeRange-play-button:hover {
    transform: scale(1.05)
}

.TimeRange-play-button>div {
    background-image: url('/assets/images/ICON_Play.png');
    height: 60%;
    margin: 20%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.TimeRange-playspeeds {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.TimeRange-playspeed {
    cursor: pointer;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.TimeRange-playspeed>input {
    display: none;
}

.TimeRange-playspeed>p {
    margin: 0;
    font-size: 0.75rem;
    text-align: center;
    background-color: #313131;
    transition: background-color 0.2s linear;
}


.TimeRange-playspeed:first-child>p {
    border-radius: 4px 4px 0 0;
}

.TimeRange-playspeed:last-child>p {
    border-radius: 0 0 4px 4px;
}

.TimeRange-playspeed>p:hover {
    background-color: #3f3f3f;
}

.TimeRange-playspeed>input:checked~p {
    background-color: #535454
}

/* TimeRange Radio Group CSS */
.TimeRange-timeline-selector {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #252525
}

.TimeRange-timeline-selector-radiobox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 6px;
    cursor: pointer;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.TimeRange-timeline-selector-radiobox>p {
    margin: 2px 0 0 0;
    font-size: 0.85rem
}

/* Hide the browser's default radio button */
.TimeRange-timeline-selector-radiobox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #2c2c2c;
    transition: background-color 0.2s linear;
}

/* On mouse-over, add a grey background color */
/* .TimeRange-timeline-selector-radiobox:hover input~.checkmark {
    background-color: #2c2c2c;
} */

.TimeRange-timeline-selector-radiobox:hover input~.checkmark:after {
    background-color: #3f3f3f;
}

/* When the radio button is checked, add a blue background */
.TimeRange-timeline-selector-radiobox input:checked~.checkmark {
    background-color: #545454;
}

/* Create the indicator (the dot/circle) */
.TimeRange-timeline-selector-radiobox .checkmark:after {
    content: "";
    position: absolute;
    display: block;
    top: 4px;
    left: 4px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #252525;
    border: 1px solid black;
    transition: background-color 0.2s linear;
}

.TimeRange-timeline-selector-radiobox input:checked~.checkmark:after {
    background: white;
}

/* DateTimePicker CSS */
.TimeRange-date-time-picker-container {
    display: flex;
    flex-direction: column;
    // margin-right: 10px;
    justify-content: center;
    margin-top: 11px;
    margin-bottom: 11px;
    justify-content: space-around;
}

.TimeRange-date-picker-column {
    width: 100%;
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.TimeRange-time-picker-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.TimeRange-date-time-done {
    width: 58%;
    height: 30px;
    margin-top: 10px;
    border-radius: 3px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #535454;
}

.TimeRange-date-time-done>span {
    margin-bottom: 2px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/* DatePicker CSS */
.react-datepicker {
    display: block;
    background-color: #1e1e1e;
    border-radius: 0;
    border: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.react-datepicker__navigation {
    outline: none;
}

.react-datepicker__header {
    background-color: #535353;
    border-radius: 0;
    border: none;
}

.react-datepicker__month {
    margin-bottom: 0;
}

.react-datepicker__current-month {
    color: #fff;
    font-weight: normal;
}

.react-datepicker__day-name {
    color: #fff;
}

.react-datepicker__day {
    color: #fff;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.react-datepicker__day:hover {
    background-color: #3f3f3f;
    border-radius: 0;
}

.react-datepicker__day--selected {
    background-color: #3158c7 !important;
    border-radius: 0;
}

.react-datepicker__day--outside-month {
    color: #535454;
}