@import "../../styles/colorDefaults.scss";

.pending-request-table {

  .red {
    span .MuiSvgIcon-root {
      color: red !important;
    }
  }
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  padding-bottom: 30px;

  .MuiPaper-root {
    background: none;
    border-radius: 0;

    .MuiButtonBase-root.MuiButton-root.MuiButton-text {
      .MuiSvgIcon-root {
        color: white;
      }
    }



    .MuiButtonBase-root.MuiButton-root.MuiButton-text.Mui-disabled.Mui-disabled {
      .MuiSvgIcon-root {
        color: rgb(146, 145, 145) !important;
      }
    }


    .MuiTableCell-head {
      // color: $secondary-font-color!important;
      background-color: black;
      color: white;
      font-weight: bold;
      font-size: 15px;
    }

    .MuiTableCell-body {
      color: white!important;
      border-bottom: none!important;
    }

    .request-table-row:hover {
      cursor: pointer;
    }

    .request-date-cell {
      min-width: 100px;
      padding: 0;
    }

    .timeframe-cell {
      display: flex;
      flex-direction: column;
      min-width: 130px;

      &>span {
        text-align: center;
      }
    }

    .status-cell {
      min-width: 320px;
      text-transform: capitalize;
    }

    .actions-cell {
      padding: 0;

      .button-container {
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
      }

      .MuiButton-text {
        padding: 0;
        margin-left: 2px;
        min-width: unset;

        .MuiSvgIcon-root {
          font-size: 17px;
        }
      }
    }
  }






 
/* Breadcrups CSS */
// .step {
//   margin: 0px 3px;
//   padding:  1px 5px;

//   border-right: solid thin gray;
//   color: white;
//   border-radius: 5px;
// }



.arrow-steps .step {
	font-size: 10px;
	text-align: center;
	color: white;
	cursor: default;
	margin-bottom:  2px;
	padding: 6px 7px 6px 20px;
	min-width: 13px;
	float: left;
	position: relative;
	background-color: gray;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none; 
  transition: background-color 0.2s ease;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
	content: " ";
	position: absolute;
	top: 0;
	right: -17px;
	width: 0;
	height: 0;
	border-top: 12px solid transparent;
	border-bottom: 15px solid transparent;
	border-left: 17px solid gray;	
	z-index: 2;
  transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
	right: auto;
	left: 0;
	border-left: 18px solid black;	
	z-index: 0;
}

.request-table-row.current {
  .arrow-steps .step:before {
    right: auto;
    left: 0;
    border-left: 18px solid $secondary-background-color;	
    z-index: 0;
  }
}
.arrow-steps .step:first-child:before {
	border: none;
}

.arrow-steps .step:first-child {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.arrow-steps .step.current {
	color: #fff;
  background-color: #F15A22; //orange
  
}

.arrow-steps .step.current:after {
	border-left: 17px solid #F15A22;	//orange
}
/* END Breadcrups CSS */



}

.modal-content {
  width: 800px;
  height: 500px;
  background: rgb(8, 8, 8);
  border-radius: 10px;
  padding: 5px;
}