@import "../../../styles/colorDefaults.scss";

.metoc-dashboard {
  flex-grow: 1;
  min-height: 1px;
  display: flex;
  flex-direction: column;
  // height: 95vh;

  .top-row {
    display: flex;
    // width: 100%;
    // flex-grow: 1;
    height: 50%;

    // max-height: 500px;
    .left-column {
      width: 55%;
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }

    .middle-column {
      width: 21%;
      border-left: solid thin gray;
      border-right: solid thin gray;
      .calendar-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90%;
    }
    }

    .right-column {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
  }

  .bottom-row {
    display: flex;
    height: 50%;

    .left-column {
      display: flex;
      flex-direction: column;
      width: 41%;
    }

    .middle-column {
      width: 35%;
      display: flex;
      flex-direction: column;
      border-left: solid thin gray;
      border-right: solid thin gray;

      .pie-charts-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 30px;
        flex-grow: 1;

        .piechart-wrapper {
          width: 50%;
          height: 280px;

          // .echarts-for-react {}
        }
      }
    }

    .right-column {
      display: flex;
      flex-direction: column;
      width: 24%;

      .bar-chart-wrapper {
        padding: 30px 5px 5px 5px;
        flex-grow: 1;
      }
    }
  }
}
