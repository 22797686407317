 @import "../../../styles/colorDefaults.scss";

.input-group-horizontal { 
    display: flex;

    label {
        display: flex;
        align-content: center;
        color: $dark-primary-font;
        font-weight: 500;
        margin:  7px 0px 5px 0px;
        font-size: 15px;

        .MuiSvgIcon-root {
            font-size: 20px;
        }

    }

        .MuiInput-input {
            padding: 8px!important;
            color: white;
            // background-color: $light-primary-background!important; 
        }
    }