@import "../../../../styles/colorDefaults.scss";

.new-request-right {
  height: 100%;

  .accordion-component-wrapper {
    overflow-x: hidden;
  }

  .MuiAccordionDetails-root {
    .placeholder-text {
      height: calc(100vh - 181px);
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .product-thumbnails {
    display: flex;
    flex-wrap: wrap;

    .MuiSvgIcon-root {
      //icon inside header area
      color: $dark-primary-font !important;
    }
  }

  .product-checkout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    margin: 10px;

    & > .info-container {
      display: flex;
      flex-direction: column;

      & > .info-item {
        & > .key {
          margin-right: 10px;
        }

        & > .value {
          color: white;
        }
      }
    }

    & > .form {
      margin-top: 20px;

      & > .MuiFormLabel-root {
        color: $dark-primary-font;
      }

      & > .MuiFormGroup-root {
        margin: 5px 0 10px 0;
        & > .MuiFormControlLabel-root {
          color: white;
          min-width: 130px;
        }
      }

      & > .MuiFormControl-root {
        width: 100%;
        margin: 5px 0;

        label {
          color: white;
          // top: -2px;
        }

        input {
          color: white;
          // padding: 15px 10px;
        }

        textarea {
          color: white;
        }

        .MuiOutlinedInput-notchedOutline {
          border-color: $dark-primary-font;
        }
      }
    }

    & > .download-button {
      width: 100%;
      align-self: center;
      margin-top: 20px;
      padding: 10px;
      border-radius: 4px;
      background-color: $secondary-background-color;
      color: white;
      text-align: center;
      letter-spacing: 0.1em;
      cursor: pointer;
    }

    & > .reset-button {
      width: 100%;
      align-self: center;
      margin-top: 20px;
      padding: 10px;
      border-radius: 4px;
      background-color: $highlight-primary-background;
      color: white;
      text-align: center;
      letter-spacing: 0.1em;
      cursor: pointer;
    }
  }
}

.reset-alert.MuiPaper-root {
  background-color: $dark-primary-background;
  color: $primary-font-color;
  & > .MuiDialogContent-root {
    & > .MuiTypography-colorTextSecondary {
      color: $light-primary-color;
    }
  }
  & > .MuiDialogActions-root {
    & > .cancel {
      color: $primary-font-color;
    }
    & > .confirm {
      color: $primary-font-color;
      background-color: $highlight-primary-background;
    }
  }
}
