@import './styles/colorDefaults';

body {
  background: black;
  color: $primary-font-color;
  overflow: hidden;

}


.App-Main-Wrapper {
  // margin-top: 10px;
  display: flex;
  flex-direction: column;
  height: 100vh;

}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  background-color: $secondary-background-color;
}


a {
  color: white;
  text-decoration: none;
}





.flex-container-wrap {
  display: flex;
  flex-wrap: wrap;
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}



// removes cesium token requirement statement
.cesium-credit-textContainer {
  display: none !important;
}


//START REACT CALENDAR STYLES
.react-calendar {
  background: none !important;
  border: none !important;
  color: white !important;
  width: 100%!important;
}

.react-calendar__tile.react-calendar__month-view__days__day {
  color: white !important;
}

.react-calendar__navigation button {
  color: $secondary-font-color !important;
}

.react-calendar__tile.react-calendar__year-view__months__month {
  color: white!important;
  border: solid thin rgb(68, 67, 67);
}
.react-calendar__tile.react-calendar__year-view__months__month:hover {
  color: black!important;
}
//END REACT CALENDAR STYLES