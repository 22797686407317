
@import "../../../styles/colorDefaults.scss";

.buttonDefault {
    width: 100%;
}



.MuiButton-containedPrimary {
    background: $mid-primary-background!important;
    // color: $dark-primary-font!important;
    margin: 5px 0px!important;
    font-size: 11px!important;
    font-weight: 700!important;
}