.main-nav-wrapper {
    // padding-top: 48px;

    .MuiAppBar-positionFixed {
        position: relative;
    }

    .right-nav-items {
        display: flex;
        align-items: center;

        .MuiButtonBase-root {
            text-transform: capitalize;
        }

        .MuiButtonBase-root:hover {
            background: rgb(66, 66, 66);
        }
    }

    .nab-btn{
        margin: 0px 5px;
    }

   
}

.MuiToolbar-regular {
    padding: 10px !important;
    min-height: 48px !important;
    height: 48px;
}

.title-container {
    display: inline-flex;
    align-items: center;

    .title {
        margin-left: 10px;
        letter-spacing: 0.05857em;
    }

    & > .logo { 
        width: 30px;
        height: 30px;
        image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    }
}