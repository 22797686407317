


$dark-primary-background:  #545454;
// $dark-primary-background:  #2799FA;
$dark-primary-font:  #1282CA;

$dark-secondary-background: #1E1E1E;
// $dark-secondary-background: #7FC4FD;
// $dark-secondary-background: #242A38;


$mid-primary-background: #777777;
$mid-primary-color: #777777;


$light-primary-background: #C5C5C5;
$light-primary-color: #C5C5C5;


$highlight-primary-background: #F15A22;
 
// $mid-blue-primary: #2150AA;
$primary-font-color: white;
$secondary-font-color: #1282CA;
$secondary-background-color: #1282CA;

$light-gray-font: rgb(238, 236, 236);

$highlight-pink: #e5018c;