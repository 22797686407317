@import '../../styles/colorDefaults.scss';

//background for current day on calendar component

.react-calendar {
    .react-calendar__tile--now {
        background: none;
    }

    .requestDates {
        background: $dark-primary-background !important;
    }

    .requestDatesSelected {
        background: $highlight-pink !important;

    }

    // .react-calendar__tile {
    //     cursor: auto;
    // }

    .react-calendar__tile:enabled:hover,
    .react-calendar__tile--active,
    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
        background: gray;
        color: white!important;
    }

  .react-calendar__decade-view__years__year, .react-calendar__century-view__decades__decade {
      color: white!important;
      border: solid thin gray;
  }

  .react-calendar__navigation__label__labelText {
      font-size: 16px;
  }
  
}