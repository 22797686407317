@import "../../styles/colorDefaults.scss";

.sidebar-dashboard-expansion-panel-header {
    .MuiAccordionSummary-content {
        margin: 0;
        width: 87%;
        display: flex;

        .title {
            white-space: nowrap;
            margin-right: 5px;
        }

        .selected-state {
            // min-width: 1px;
            // flex-shrink: 1;
            // word-break: break-word;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: $highlight-primary-background;
            // font-size: 17px;
        }
    }
}

.MuiAccordion-root {
    border-radius: 0 !important;
}


.filter-container {
    background: rgb(23, 23, 24);
    padding: 3px;

    .MuiFormControl-root {
        flex-grow: 1;
        margin-right: 15px;

        .MuiInput-underline:after {
            border-color: $dark-primary-font;
        }
    }
}