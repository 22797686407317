.success-snackbar {
    &>.MuiSnackbarContent-root {
        background-color: #4caf50;
    }
}

.error-snackbar {
    &>.MuiSnackbarContent-root {
        background-color: #f44336;
    }
}