@import "../../styles/colorDefaults.scss";

.ProductTile {
  width: 134px;
  height: 134px;
  display: flex;
  flex-direction: column;
  position: relative;
  user-select: none;
  border: 2px solid black;
  transition: border 0.2s linear;

  &.selected {
    border: 2px solid $highlight-primary-background;
  }

  & > .image {
    height: 60%;
    background-color: rgb(180, 42, 42);
    background-size: cover;
    background-position: center;
  }

  & > .textbox {
    height: 40%;
    padding: 5px;
    background-color: rgb(77, 77, 77);

    & > .name {
      width: 100%;
      height: 100%;
      font-size: 0.88rem;
      color: white;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  & > .badge {
    position: absolute;
    right: 2px;
    top: 2px;
    background-color: dodgerblue;
    border: 1px solid black;
    color: white;
    padding: 0 5px 2px 5px;
    border-radius: 2px;
  }
}

.ProductTile:hover {
  cursor: pointer;
}

.ProductTile-tooltip {
  background-color: black !important;
  max-width: 450px !important;

  & > span::before {
    background-color: black;
  }

  & > .tooltip-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > span {
      text-align: center;
      font-size: 0.8rem;
      margin: 2px 0;
    }

    & > span.description {
      margin-top: 10px;
      text-align: justify;
    }
  }
}
