
@import "../../styles/colorDefaults.scss";

.chat-box {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
 
    //START message received
    .chat-messages-wrapper {
        height: 92%;
        padding: 10px;

        .message-wrap-received {
            margin-bottom: 15px;
    
            .message-signature {
                color: $dark-primary-font;
                font-size: 12px;
                margin-bottom: 3px;
                
                .name {
                    font-weight: bold;
                    margin-right: 8px;
                }
            }
           
            .message-bubble {
                display: inline-block;
                max-width: 80%;
                padding: 10px;
                background: $mid-primary-background;
                color: white;
                border-radius: 10px;
            }
        }
        //END message received
    

        //START message sent
        .message-wrap-sent {
            display: flex;
            flex-direction: column;
            background: none;
            margin-bottom: 15px;
            justify-content: flex-end;
            align-items: flex-end;
            
            .message-signature {
                display: flex;
                color: $dark-primary-font;
                font-size: 12px;
                margin-bottom: 3px;
                justify-content: flex-end;
    
                
                .name {
                    font-weight: bold;
                    margin-left: 8px;
                }
            }
           
            .message-bubble {
                display: inline-block;
                padding: 10px;
                background: white;
                color: $dark-primary-font;
                max-width: 80%;
                border-radius: 10px;

            }
        }
        //END message sent
    }

    .message-sender {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: solid thin $dark-primary-background;
        background: rgba(153, 153, 153, 0.486);

        input:focus{
            outline: none;
        }
        ::placeholder { 
            color: white;
          }
        .textBox {
            width: 88%;
            height: 35px;
            padding-right: 50px;
            border: none;
            // color: $dark-primary-font;
            color: white;
            background: none;
        }
        .submitBtn {
            margin-left: -0px;
            // color: $dark-primary-font!important;
            color: white;
            font-weight: bold;
            height: 25px;
            // width: 60px;
            margin-right: 10px;
            background: none;
            // border: 0;
        }
        .submitBtn:hover {
            cursor: pointer;
        }
    } 
}